import * as React from "react"
import {
  Box,
  Center,
  Heading,
  Tag,
  Image,
  useMultiStyleConfig,
} from "@chakra-ui/react"
import smartphone from "../images/smartphone.png"

const Creative = ({ video, heading, tags, variant, background, children, tagColor, tagBackground, headingColor, backImage, frontImage, ...props }) => {
  const styles = useMultiStyleConfig("Creative", { variant })
  return (
    
    <Center width="100vw" height="100vh" {...props}>
      <Image sx={styles.background} src={background} />
      <Box __css={styles.container}>
        <Image __css={styles.backImage} src={backImage} className="backImage"/>
        <Image __css={styles.frontImage} src={frontImage} className="frontImage"/>
        <Box __css={styles.smartphone} backgroundImage={smartphone} className="smartphone">
          <Box __css={styles.video} as="video" autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </Box>
        </Box>
        <Heading sx={styles.heading} color={headingColor}>{heading}</Heading>
        <Box __css={styles.tags}>
          {tags.map(tag => (
            <Tag sx={styles.tag} backgroundColor={tagBackground} color={tagColor}>{tag}</Tag>
          ))}
        </Box>
        <Box sx={styles.elements}>
          {children}
        </Box>
      </Box>
    </Center>
  )
}

export default Creative
