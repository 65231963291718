import * as React from "react"
import './BezNonsensow.css';
import Creative from "../../components/Creative"
import backgroundBezNonsensow from "./images/bezNonsensow_background.jpg"
import bezNonensowMan from "./images/bezNonsensow_man.png"
import bezNonensowNonsens from "./images/bezNonsensow_nonsens.png"
import bezNonensowTable from "./images/bezNonsensow_table.jpg"
import video from "./images/t-mobile_video.mp4"
import SimpleSidebar from "../../components/layout"

const BezNonsensow = () => (
  <SimpleSidebar>
    <Creative
      className="bezNonsensow"   
      video={video}
      background={backgroundBezNonsensow}
      heading="Bez Nonsensów"
      tags={["choice", "interaction", "animation"]}  
      tagBackground="#000000"
      tagColor="#ffffff"
      headingColor="#ffffff"
    > 
      <img src={bezNonensowTable} className="bezNonsensow_table"/>    
      <img src={bezNonensowMan} className="bezNonsensow_image" />     
      <img src={bezNonensowNonsens} className="bezNonsensow_image bezNonsensow_nonsens" />    
    </Creative> 
  </SimpleSidebar>
)

export default BezNonsensow
